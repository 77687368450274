import {createSlice} from "@reduxjs/toolkit";
import {Units} from "../../../settings/enums";
import moment from 'moment';

const initialState = {
    startDate: null,
    endDate: null,
    vesselLength: undefined,
    vesselBeam: undefined,
    vesselDraft: undefined,
    vesselType: undefined,
    hullType: undefined,
    natureOfTravel: undefined,
    redeemNights: undefined,
    liveAboard: undefined,
    error: undefined,
    proposal: undefined,
    timerStart: undefined,
    secondsLeft: undefined,
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    mail: undefined,
    address: undefined,
    addressLineTwo: undefined,
    postCode: undefined,
    country: undefined,
    vesselName: undefined,
    needWaterElectricity: undefined,
    homeMarinaName: undefined,
    homeMarinaId: undefined,
    power: undefined,
    notes: undefined,
    reservationDetails: undefined,
    vessel: undefined,
    vesselsSameDimensions: undefined,
    contract_id: undefined,
    units: Units.METERS,
    timerExtended: false,
    forceLogin: false,
    finished: false,
    cardError: false,
    forceTimer: false,
    isNewVessel:true,
    promotions: [],
    isLoggedInStep1: false,
    isRequest: undefined,
    vesselPhoto: undefined,
    vesselInsurance: undefined,
    vesselLicense: undefined,
    skiperId: undefined,
    attachment_ids:[],
    calculatedPrice: undefined,
    usePopeyeBalance: true
};

const reservationSlice = createSlice({
    name: 'reservation',
    initialState,
    reducers: {
        clear(state) {
            state.startDate = null;
            state.endDate = null;
            state.vesselLength = undefined;
            state.vesselBeam = undefined;
            state.vesselDraft = undefined;
            state.vesselType = undefined;
            state.hullType = undefined;
            state.natureOfTravel = undefined;
            state.redeemNights = 0;
            state.error = undefined;
            state.proposal = undefined;
            state.timerStart = undefined;
            state.secondsLeft = undefined;
            state.timerExtended = false;
            state.firstName = undefined;
            state.lastName = undefined;
            state.phone = undefined;
            state.mail = undefined;
            state.vesselName = undefined;
            state.power = undefined;
            state.notes = undefined;
            state.reservationDetails = undefined;
            state.forceLogin = false;
            state.finished = false;
            state.vessel = undefined;
            state.homeMarinaName = undefined;
            state.homeMarinaId = undefined;
            state.needWaterElectricity = false;
            state.country = undefined;
            state.postCode = undefined;
            state.address = undefined;
            state.addressLineTwo = undefined;
            state.timerExtended = false;
            state.timerStart = undefined;
            state.secondsLeft = undefined;
            state.vesselsSameDimensions=undefined;
            state.isNewVessel=true;
            state.isLoggedInStep1=false;
            state.isRequest= undefined;
            state.vesselPhoto = undefined;
            state.vesselInsurance =undefined;
            state.vesselLicense =undefined;
            state.skiperId =undefined;
            state.liveAboard=undefined;
            state.attachment_ids=[];
            state.calculatedPrice = undefined;
            state.usePopeyeBalance = true;
            let remaining = sessionStorage.length;
            while(remaining--){
                const key = sessionStorage.key(remaining);
                if(key.startsWith('reservation.')){
                    sessionStorage.removeItem(key);
                }
            }

        },
        setUsePopeyeBalance(state, action) {
            state.usePopeyeBalance = action.payload;
            sessionStorage.setItem('reservation.usePopeyeBalance', JSON.stringify(action.payload || false));
        },
        setLiveaboard(state, action){
            state.liveAboard = action.payload;
            sessionStorage.setItem('reservation.liveAboard', action.payload || '')
        },
        setContractId(state, action){
            state.contract_id = action.payload;
            sessionStorage.setItem('reservation.contract_id', action.payload || '')
        },
        setFirstName(state, action) {
            state.firstName = action.payload;
            sessionStorage.setItem('reservation.firstName', action.payload || '');
        },
        setForceLogin(state, action) {
            state.forceLogin = action.payload;
            sessionStorage.setItem('reservation.forceLogin', JSON.stringify(action.payload));
        },
        setLastName(state, action) {
            state.lastName = action.payload;
            sessionStorage.setItem('reservation.lastName', action.payload || '');
        },
        setPhone(state, action) {
            state.phone = action.payload;
            sessionStorage.setItem('reservation.phone', action.payload || '');
        },
        setVesselName(state, action) {
            state.vesselName = action.payload;
            sessionStorage.setItem('reservation.vesselName', action.payload || '');
        },
        setNotes(state, action) {
            state.notes = action.payload;
            sessionStorage.setItem('reservation.notes', action.payload || '');
        },
        setPower(state, action) {
            state.power = action.payload;
            sessionStorage.setItem('reservation.power', action.payload || '');
        },
        setMail(state, action) {
            state.mail = action.payload;
            sessionStorage.setItem('reservation.mail', action.payload || '');
        },
        setAddress(state, action) {
            state.address = action.payload;
            sessionStorage.setItem('reservation.address', action.payload || '');
        },
        setAddressLineTwo(state, action) {
            state.addressLineTwo = action.payload;
            sessionStorage.setItem('reservation.addressLineTwo', action.payload || '');
        },
        setPostCode(state, action) {
            state.postCode = action.payload;
            sessionStorage.setItem('reservation.postCode', action.payload || '');
        },
        setCountry(state, action) {
            state.country = action.payload;
            sessionStorage.setItem('reservation.country', action.payload || '');
        },
        clearBerthLock(state, action) {},
        setReservationProposal(state, action) {
            state.proposal = action.payload;
            if(action.payload) {
                sessionStorage.setItem('reservation.proposal', JSON.stringify(action.payload));
            } else {
                sessionStorage.removeItem('reservation.proposal');
            }
        },
        setStartDate(state, action) {
            state.startDate = action.payload;
            sessionStorage.setItem('reservation.startDate', JSON.stringify(action.payload));
        },
        setEndDate(state, action) {
            state.endDate = action.payload;
            sessionStorage.setItem('reservation.endDate', JSON.stringify(action.payload));
        },
        setVesselLength(state, action) {
            state.vesselLength = action.payload;
            sessionStorage.setItem('reservation.vesselLength', JSON.stringify(action.payload));
        },
        setVesselBeam(state, action) {
            state.vesselBeam = action.payload;
            sessionStorage.setItem('reservation.vesselBeam', JSON.stringify(action.payload));
        },
        setVesselDraft(state, action) {
            state.vesselDraft = action.payload;
            sessionStorage.setItem('reservation.vesselDraft', JSON.stringify(action.payload));
        },
        setVesselType(state, action) {
            state.vesselType = action.payload;
            sessionStorage.setItem('reservation.vesselType', action.payload || '');
        },
        setHullType(state, action) {
            state.hullType = action.payload;
            sessionStorage.setItem('reservation.hullType', action.payload || '');
        },
        setNeedWaterElectricity(state, action) {
            state.needWaterElectricity = action.payload;
            sessionStorage.setItem('reservation.needWaterElectricity', JSON.stringify(action.payload));
        },
        setPasseportEscales(state, action) {
            state.passeportEscales = action.payload;
            sessionStorage.setItem('reservation.passeportEscales', JSON.stringify(action.payload));
        },
        setNatureOfTravel(state, action) {
            state.natureOfTravel = action.payload;
            sessionStorage.setItem('reservation.natureOfTravel', action.payload || '');
        },
        setRedeemNights(state, action) {
            state.redeemNights = action.payload;
            sessionStorage.setItem('reservation.redeemNights', action.payload || '');
        },
        setHomeMarinaId(state, action) {
            state.homeMarinaId = action.payload;
            sessionStorage.setItem('reservation.homeMarinaId', action.payload || '');
        },
        setHomeMarinaName(state, action) {
            state.homeMarinaName = action.payload;
            sessionStorage.setItem('reservation.homeMarinaName', action.payload || '');
        },
        setUnits(state, action) {
            if (action.payload === Units.METERS && state.units === Units.FEET) {
                if (state.vesselLength !== undefined) {
                    state.vesselLength = parseFloat((state.vesselLength * 3.280839895).toFixed(2));
                }
                if (state.vesselBeam !== undefined) {
                    state.vesselBeam = parseFloat((state.vesselBeam * 3.280839895).toFixed(2));
                }
                if (state.vesselDraft !== undefined) {
                    state.vesselDraft = parseFloat((state.vesselDraft * 3.280839895).toFixed(2));
                }
            } else if (action.payload === Units.FEET && state.units === Units.METERS) {
                if (state.vesselLength !== undefined) {
                    state.vesselLength = parseFloat((state.vesselLength * 0.3048).toFixed(2));
                }
                if (state.vesselBeam !== undefined) {
                    state.vesselBeam = parseFloat((state.vesselBeam * 0.3048).toFixed(2));
                }
                if (state.vesselDraft !== undefined) {
                    state.vesselDraft = parseFloat((state.vesselDraft * 0.3048).toFixed(2));
                }
            }
            state.units = action.payload;
        },
        loadReservationProposals(state, action) {
            state.startDate = action.payload.start_date;
            state.endDate = action.payload.end_date;
            state.vesselLength = action.payload.vessel_length;
            state.vesselBeam = action.payload.vessel_beam;
            state.vesselDraft = action.payload.vessel_draft;
            state.vesselType = action.payload.vessel_type;
            state.hullType = action.payload.hull_type;
            state.natureOfTravel = action.payload.nature_of_travel;
            state.liveAboard = action.payload.live_aboard;
            state.contract_id = action.payload.contract_id;
            state.redeemNights = action.payload.requested_redeem_nights_amount;
            state.passeportEscales = action.payload.passeport_escales;
            state.usePopeyeBalance = action.payload.use_popeye_balance;
            state.error = undefined;

            sessionStorage.setItem('reservation.usePopeyeBalance', JSON.stringify(action.payload.use_popeye_balance));
            sessionStorage.setItem('reservation.startDate', JSON.stringify(action.payload.start_date));
            sessionStorage.setItem('reservation.endDate', JSON.stringify(action.payload.end_date));
            sessionStorage.setItem('reservation.vesselLength', JSON.stringify(action.payload.vessel_length));
            sessionStorage.setItem('reservation.vesselBeam', JSON.stringify(action.payload.vessel_beam));
            sessionStorage.setItem('reservation.vesselDraft', JSON.stringify(action.payload.vessel_draft));
            sessionStorage.setItem('reservation.vesselType', action.payload.vessel_type || '');
            sessionStorage.setItem('reservation.hullType', action.payload.hull_type || '');
            sessionStorage.setItem('reservation.natureOfTravel', action.payload.nature_of_travel || '');
            sessionStorage.setItem('reservation.redeemNights', action.payload.redeem_nights || 0);
            sessionStorage.setItem('reservation.passeportEscales', action.payload.passeport_escales || false);
        },
        reloadReservationProposals(state) {
            state.error = undefined;
            state.reservationDetails = undefined;
            state.isRequest=undefined;
            state.forceLogin = false;
            state.error = undefined;
        },
        setVessel(state, action) {
            state.vessel = action.payload;
            sessionStorage.setItem('reservation.vessel', JSON.stringify(action.payload));
        },
        setVesselsSameDimensions(state, action){
            state.vesselsSameDimensions=action.payload;
            sessionStorage.setItem('reservation.vesselsSameDimensions',JSON.stringify(action.payload));
        },
        setIsNewVessel(state, action){
            state.isNewVessel=action.payload;
            sessionStorage.setItem('reservation.isNewVessel', JSON.stringify(action.payload))
        },
        setIsLoggedInStep1(state, action){
            state.isLoggedInStep1 = action.payload;
            sessionStorage.setItem('reservation.isLoggedInStep1', JSON.stringify(action.payload))
        },
        loadReservationProposalsSuccess(state, action) {
            state.proposal = action.payload;
            state.timerExtended = false;
            sessionStorage.setItem('reservation.proposal', JSON.stringify(action.payload));
            sessionStorage.setItem('reservation.timerExtended', JSON.stringify(false));
        },
        loadReservationProposalsFailure(state, action) {
            state.error = action.payload;
        },
        setTimerSecondsLeft(state, action) {
            state.secondsLeft = action.payload;
            sessionStorage.setItem('reservation.secondsLeft', JSON.stringify(action.payload));
        },
        setTimerExtended(state, action) {
            state.timerExtended = action.payload;
            sessionStorage.setItem('reservation.timerExtended', JSON.stringify(action.payload));
        },
        setCalculatedPrice(state,action) {
            state.calculatedPrice = action.payload;
            sessionStorage.setItem('reservation.calclatedPrice', JSON.stringify(action.payload));
        },
        reserveBerth(state) {

        },
        startTimer(state) {
            const now = (new moment()).unix();
            state.timerStart = now;
            sessionStorage.setItem('reservation.timerStart', JSON.stringify(now));
        },
        timerTick(state, action) {
        },
        proceedTimer(state) {
        },
        stopTimer(state) {
        },
        extendTimer(state) {
            state.timerExtended = true;
            sessionStorage.setItem('reservation.timerExtended', JSON.stringify(true));
        },
        createReservation(state) {
        },
        createReservationSuccess(state, action) {
            state.reservationDetails = action.payload.reservation;
            state.error=undefined;
            sessionStorage.setItem('reservation.reservationDetails', JSON.stringify(state.reservationDetails));

        },
        setReservationDetails(state, action) {
            state.reservationDetails = action.payload;
        },
        createReservationFailure(state, action) {
            if(action.payload.code === 409 && action.payload.error === 'USER_ALREADY_EXISTS') {
                state.forceLogin = true;
                sessionStorage.setItem('reservation.forceLogin', JSON.stringify(true));
            }
            state.error = action.payload.error;

        },
        finishReservation(state) {
        },
        finishReservationSuccess(state, action) {
            state.finished = true;
            sessionStorage.setItem('reservation.finished', JSON.stringify(true));
        },
        finishReservationFailure(state, action) {
            state.error = action.payload;
        },
        setFinished(state, action) {
            state.finished = action.payload;
            sessionStorage.setItem('reservation.finished', JSON.stringify(action.payload));
        },
        setCardError(state, action) {
            state.cardError = action.payload;
        },
        setForceTimer(state, action) {
            state.forceTimer = action.payload;
        },
        setPromotions(state, action) {
            state.promotions = action.payload;
        },
        sendBookingRequest(state, action) {
            state.isRequest = true;
        },
        setReservationError(state, action){
            state.error = action.payload;
        },
        setvesselInsuranceFile(state,action){
            state.vesselInsurance=action.payload;
            sessionStorage.setItem('reservation.vesselInsurance', action.payload || '');
        },
        setvesselLicenseFile(state,action){
            state.vesselLicense=action.payload;
            sessionStorage.setItem('reservation.vesselLicense', action.payload || '');
        },
        setskiperIdFile(state,action){
            state.skiperId=action.payload;
            sessionStorage.setItem('reservation.skiperId', action.payload || '');
        },
        setvesselPhotoFile(state,action){
            state.vesselPhoto=action.payload;
            sessionStorage.setItem('reservation.vesselPhoto', action.payload || '');
        },
        setAttachmentIds(state,action){
            state.attachment_ids= action.payload;
            sessionStorage.setItem('reservation.attachment_ids', action.payload || '');
        }
    }
});

export const reservationActions = reservationSlice.actions;

export const reservationReducer = reservationSlice.reducer;

export default reservationSlice;
