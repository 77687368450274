import {call, put, takeLatest, delay, select} from "redux-saga/effects";
import moment from 'moment';
import {reservationActions} from "./reservationSlice";
import reservationService from "../../../services/reservation";
import {
    selectBoaterAddress,
    selectBoaterAddressLine2,
    selectBoaterFirstName,
    selectBoaterLastName,
    selectBoaterMail,
    selectBoaterPhone,
    selectCountry,
    selectFinished,
    selectHomeMarina,
    selectHullType,
    selectNatureOfTravel,
    selectNotes,
    selectPostalCode,
    selectReservationDetails,
    selectReservationEndDate,
    selectReservationProposal, selectReservationSecondsLeft,
    selectReservationStartDate, selectReservationTimerExtended,
    selectVessel,
    selectIsNewVessel,
    selectLiveAboard,
    selectVesselBeam,
    selectVesselDraft,
    selectVesselLength,
    selectVesselName,
    selectVesselType,
    selectWaterElectricity,
    selectContractId,
    selectVesselPhoto,
    selectVesselLicense,
    selectVesselInsurance,
    selectSkiperId,
    selectAttachment_Ids, selectRedeemNights, selectPasseportEscales, selectReservationTimerStartTime,
    selectHomeMarinaName,
    selectHomeMarinaId,
    selectUsePopeyeBalance
} from "./selectors";
import {selectMarinaId} from "../app/selectors";
import {selectBoater, selectUser} from "../auth/selectors";
import {appActions} from "../app/appSlice";

const timeout = process.env.REACT_APP_SESSION_TIMEOUT_MINUTES ?
    parseFloat(process.env.REACT_APP_SESSION_TIMEOUT_MINUTES) : 10;

function* loadReservationProposals(action) {
    yield put(appActions.setAppLoading(true));
    const user = yield select(selectUser);
    try {
        if(action.payload.passeport_escales) {
            yield put(reservationActions.loadReservationProposalsSuccess({
                available_berths: 0,
                lock_token: ''
            }));
        } else {
            const res = yield call(user ?
                reservationService.getReservationProposal : reservationService.getReservationProposalForGuest,
                action.payload);
            if (!res.success) {
                if (res.code === 403) {
                    yield put(reservationActions.loadReservationProposalsSuccess({
                        available_berths: 0,
                        lock_token: ''
                    }));
                } else {
                    yield put(reservationActions.loadReservationProposalsFailure('ERROR_LOADING_PROPOSALS'));
                }
                yield put(appActions.setAppLoading(false));
                return;
            }
            yield put(reservationActions.loadReservationProposalsSuccess(res.data));
            if(action.payload.prev_lock_token) {
                yield put(reservationActions.reserveBerth());
            }
        }
    } catch(e) {
        yield put(reservationActions.loadReservationProposalsFailure('ERROR_LOADING_PROPOSALS'));
    }
    yield put(appActions.setAppLoading(false));
}

function* reloadReservationProposals() {
    const proposal = yield select(selectReservationProposal);
    const marina_id = yield select(selectMarinaId);
    const start_date = yield select(selectReservationStartDate);
    const end_date = yield select(selectReservationEndDate);
    const vessel_length = yield select(selectVesselLength);
    const vessel_beam = yield select(selectVesselBeam);
    const vessel_draft = yield select(selectVesselDraft);
    const vessel_type = yield select(selectVesselType);
    const hull_type = yield select(selectHullType);
    const nature_of_travel = yield select(selectNatureOfTravel);
    const use_popeye_balance = yield select(selectUsePopeyeBalance);
    const payload = {
        marina_id,
        start_date,
        end_date,
        vessel_length,
        vessel_beam,
        vessel_draft,
        vessel_type,
        hull_type,
        nature_of_travel,
        use_popeye_balance,
        prev_lock_token: proposal.lock_token,
        berth_id: proposal?.berth_id
    }
    yield put(reservationActions.loadReservationProposals(payload));
}

function* startTimer() {
    yield put(reservationActions.timerTick());
}

function* timerTick() {
    const startTime = yield select(selectReservationTimerStartTime);
    const secondsPassed = ((new moment()).unix() - startTime);
    const secondsLeft = Math.max(timeout * 60 - secondsPassed);
    yield put(reservationActions.setTimerSecondsLeft(secondsLeft * 1000));
    yield delay(1000);
    if(secondsLeft > 0) {
        yield put(reservationActions.timerTick());
    } else {
        const finished = select(selectFinished);
        if(!finished) {
            yield put(reservationActions.clearBerthLock());
        }
    }
}

function* proceedTimer() {
    const extended = yield select(selectReservationTimerExtended);
    const secondsLeft = yield select(selectReservationSecondsLeft);
    if(!extended) {
        yield put(reservationActions.extendTimer());
        yield put(reservationActions.startTimer());
    } else if(secondsLeft && secondsLeft > 0) {
        yield put(reservationActions.timerTick());
    }
}

function* createReservation() {
    yield put(appActions.setAppLoading(true));
    const marina_id = yield select(selectMarinaId);
    const start_date = yield select(selectReservationStartDate);
    const end_date = yield select(selectReservationEndDate);
    const length = yield select(selectVesselLength);
    const beam = yield select(selectVesselBeam);
    const draft = yield select(selectVesselDraft);
    const vessel_type = yield select(selectVesselType);
    const hull_type = yield select(selectHullType);
    const nature_of_travel = yield select(selectNatureOfTravel);
    const live_aboard = yield select(selectLiveAboard);
    const first_name = yield select(selectBoaterFirstName);
    const last_name = yield select(selectBoaterLastName);
    const email = yield select(selectBoaterMail);
    const phone = yield select(selectBoaterPhone);
    const address = yield select(selectBoaterAddress);
    const address_line_two = yield select(selectBoaterAddressLine2);
    const postal_code = yield select(selectPostalCode);
    const country = yield select(selectCountry);
    const vessel_name = yield select(selectVesselName);
    const water_electricity = yield select(selectWaterElectricity);
    const home_marina_name = yield select(selectHomeMarinaName);
    const home_marina_id = yield select(selectHomeMarinaId);
    const notes = yield select(selectNotes);
    const user = yield select(selectUser);
    const vessel = yield select(selectVessel);
    //const selectedBoater = yield select(selectBoater);
    const proposal = yield select(selectReservationProposal);
    const isNewVessel = yield select(selectIsNewVessel);
    const contract_id = yield select(selectContractId);
    const vessel_photo = yield select(selectVesselPhoto);
    const vessel_license = yield select(selectVesselLicense);
    const vessel_insurance = yield select(selectVesselInsurance);
    const skiper_id = yield select(selectSkiperId);
    const attachments = [vessel_photo, vessel_license, vessel_insurance, skiper_id].filter(Boolean);
    const attachment_ids= yield select(selectAttachment_Ids);
    const use_popeye_balance = yield select(selectUsePopeyeBalance);

    let total = proposal?.price?.total_to_pay ?? 0;
    if(use_popeye_balance && proposal?.price?.popeye_discount?.total_discount_via_points &&
        proposal?.price?.popeye_discount?.total_points_to_redeem) {
        total = total - proposal?.price?.popeye_discount?.total_discount_via_points;
    }

    const is_zero_reservation_cost = total === 0;

    const updatedVessel= { ...(vessel ? vessel: {}),
            name:vessel_name,
            length,
            beam,
            draft,
            hull_type: hull_type? hull_type:vessel?.hull_type,
            type:vessel_type?vessel_type:vessel?.type,
            home_marina_name: home_marina_name && home_marina_name.length > 0 ? home_marina_name : vessel?.home_marina_name,
            home_marina_id: home_marina_id || vessel?.home_marina_id,
            water_electricity: water_electricity? water_electricity:vessel?.water_electricity
    };

    const boater = {...(user ? {...user} : {}),
        email,
        first_name,
        last_name,
        phone: phone && phone.length > 0 ? phone : undefined,
        address: address && address.length > 0 ? address : undefined,
        address_line_two: address_line_two && address_line_two.length > 0 ? address_line_two : undefined,
        country_of_residence: country && country.length > 0 ? country : undefined,
        postal_code: postal_code && postal_code.length > 0 ? postal_code : undefined,
    };
    delete (boater.vessels);

    const request = {
        instant_booking: true,
        marina_id,
        start_date,
        end_date,
        create_vessel:isNewVessel,
        boater_id: boater?.id,
        vessel: (!isNewVessel && vessel) ? updatedVessel : {
            name: vessel_name,
            length,
            beam,
            draft,
            hull_type,
            type: vessel_type,
            nature_of_travel,
            home_marina_name: home_marina_name && home_marina_name.length > 0 ? home_marina_name : undefined,
            home_marina_id,
            water_electricity
        },
        boater,
        notes,
        berth_id: proposal?.berth_id || '',
        lock_token: proposal?.lock_token || '',
        nature_of_travel,
        live_aboard,
        contract_id,
        attachments,
        attachment_ids,
        use_popeye_balance,
        is_zero_reservation_cost
    };

    try {
        const res = yield user ?
            call(reservationService.createWithResult, request)
            :
            call(reservationService.createGuestWithResult, request);
        if(!res.success) {
            yield put(reservationActions.createReservationFailure({error: (!!res.data && res.data.error_code) || 'ERROR_CREATING_RESERVATION', code: res.code}));
            yield put(appActions.setAppLoading(false));
            return;
        }
        yield put(reservationActions.createReservationSuccess({reservation: res.data}));
        if(is_zero_reservation_cost) {
            yield put(reservationActions.finishReservation());
        }
    } catch(e) {
        yield put(reservationActions.createReservationFailure({error: 'ERROR_CREATING_RESERVATION', code: -1}));
    }
    yield put(appActions.setAppLoading(false));
}

function* sendBookingRequest(action) {
    yield put(appActions.setAppLoading(true));
    const marina_id = yield select(selectMarinaId);
    const start_date = yield select(selectReservationStartDate);
    const end_date = yield select(selectReservationEndDate);
    const length = yield select(selectVesselLength);
    const beam = yield select(selectVesselBeam);
    const draft = yield select(selectVesselDraft);
    const Vessel_name = yield select(selectVesselName);
    const vessel_type = yield select(selectVesselType);
    const hull_type = yield select(selectHullType);
    const nature_of_travel = yield select(selectNatureOfTravel);
    const requested_redeem_nights_amount = yield select(selectRedeemNights);
    const user = yield select(selectUser);
    // const selectedBoater = yield select(selectBoater);
    const live_aboard = yield select(selectLiveAboard);
    const passeport_escales = yield select(selectPasseportEscales);
    const proposal = yield select(selectReservationProposal);
    const contract_id = yield select(selectContractId);
    const isNewVessel = yield select(selectIsNewVessel);
    const selectedVessel = yield select(selectVessel);

    const phone = yield select(selectBoaterPhone);
    const address = yield select(selectBoaterAddress);
    const postal_code = yield select(selectPostalCode);
    const country = yield select(selectCountry);
    const home_marina_name = yield select(selectHomeMarinaName);
    const home_marina_id = yield select(selectHomeMarinaId);
    const first_name = yield select(selectBoaterFirstName);
    const last_name = yield select(selectBoaterLastName);
    const water_electricity = yield select(selectWaterElectricity);
    const vessel_photo = yield select(selectVesselPhoto);
    const vessel_license = yield select(selectVesselLicense);
    const vessel_insurance = yield select(selectVesselInsurance);
    const skiper_id = yield select(selectSkiperId);
    const attachments = [vessel_photo, vessel_license, vessel_insurance, skiper_id].filter(Boolean);
    const attachment_ids= yield select(selectAttachment_Ids);


    yield put(reservationActions.setMail(action.payload));
    //need to take into consideration any other field that can be changed?
    const updatedVessel= { ...(selectedVessel ? selectedVessel: {}),
        name:Vessel_name,
        length,
        beam,
        draft,
        hull_type: hull_type? hull_type:selectedVessel?.hull_type,
        type:vessel_type?vessel_type:selectedVessel?.type,
        home_marina_name: home_marina_name && home_marina_name.length > 0 ? home_marina_name : selectedVessel?.home_marina_name,
        home_marina_id: home_marina_id || selectedVessel?.home_marina_id,
        water_electricity: water_electricity? water_electricity:selectedVessel?.water_electricity
    };

    const boater = {...(user ? {...user} : {}),
        email:action.payload,
        first_name,
        last_name,
        phone: phone && phone.length > 0 ? phone : undefined,
        address: address && address.length > 0 ? address : undefined,
        country_of_residence: country && country.length > 0 ? country : undefined,
        postal_code: postal_code && postal_code.length > 0 ? postal_code : undefined,
};
    delete (boater.vessels);

    const request = {
        marina_id,
        start_date,
        end_date,
        create_vessel: isNewVessel,
        boater_id: boater?.id || undefined,
        vessel: (!isNewVessel && selectedVessel )? updatedVessel : {

            name: Vessel_name? Vessel_name : 'New Vessel',
            length,
            beam,
            draft,
            hull_type,
            type: vessel_type,
            nature_of_travel,
            home_marina_name: home_marina_name && home_marina_name.length > 0 ? home_marina_name : undefined,
            home_marina_id: home_marina_id || undefined
        },
        boater: boater ? boater : {
            email: action.payload
        },
        berth_id: proposal?.berth_id || '',
        lock_token: proposal?.lock_token || '',
        nature_of_travel,
        live_aboard,
        passeport_escales,
        contract_id,
        request:true,
        attachments,
        attachment_ids,
        requested_redeem_nights_amount
    };

    try {
        const res = yield user ?
            call(reservationService.createWithResult, request)
            :
            call(reservationService.createGuestWithResult, request);
        if(!res.success) {
            yield put(reservationActions.createReservationFailure({error: (res.data && res.data.error_code) || 'ERROR_CREATING_RESERVATION', code: res.code}));
            yield put(appActions.setAppLoading(false));
            return;
        }
        yield put(reservationActions.createReservationSuccess({reservation: res.data}));
    } catch(e) {
        yield put(reservationActions.createReservationFailure({error: 'ERROR_CREATING_RESERVATION', code: -1}));
    }
    yield put(appActions.setAppLoading(false));
}

function* finishReservation() {
    yield put(appActions.setAppLoading(true));
    const user = yield select(selectUser);
    const reservation = yield select(selectReservationDetails);
    const proposal = yield select(selectReservationProposal);
    const use_popeye_balance = yield select(selectUsePopeyeBalance);

    let total = proposal?.price?.total_to_pay ?? 0;
    if(use_popeye_balance && proposal?.price?.popeye_discount?.total_discount_via_points &&
        proposal?.price?.popeye_discount?.total_points_to_redeem) {
        total = total - proposal?.price?.popeye_discount?.total_discount_via_points;
    }

    const is_zero_reservation_cost = total === 0;

    try {
        if(is_zero_reservation_cost) {
            const res = yield reservationService.update({
                is_zero_reservation_cost,
                use_popeye_balance,
                reservation_id: reservation?.id || '',
                method: 'ACCEPT',
                vessel: { id: reservation.vessel.id }
            });
            yield put(reservationActions.finishReservationSuccess());
        } else {
            const request = {
                reservation_id: reservation?.id || '',
                boater_id: reservation?.boater_id || '',
                stripe_transaction_id: reservation?.payment?.stripe_intent_id || undefined,
                use_popeye_balance,
                berth_id: proposal?.berth_id || '',
                lock_token: proposal?.lock_token || '',
                is_zero_reservation_cost
            };
            const res = yield !user.is_guest ?
                call(reservationService.updatePaymentWithResult, request)
                :
                call(reservationService.updateGuestPaymentWithResult, request);
            if (!res.success) {
                yield put(reservationActions.finishReservationFailure('ERROR_FINISHING_RESERVATION'));
                yield put(appActions.setAppLoading(false));
                return;
            }
            console.log("reservationSaga finishreservationSUccess");
            yield put(reservationActions.finishReservationSuccess());
        }
    } catch(e) {
        yield put(reservationActions.finishReservationFailure('ERROR_FINISHING_RESERVATION'));
    }
    yield put(appActions.setAppLoading(false));
}

function* reserveBerth() {
    yield put(appActions.setAppLoading(true));
    const proposal = yield select(selectReservationProposal);

    const request = {
        action: 'extend_to_booking_step',
        berth_id: proposal?.berth_id || '',
        lock_token: proposal?.lock_token || ''
    }
    try {
        const res = yield call(reservationService.extendAvailability, request);
        if(res.success) {
            yield put(reservationActions.startTimer());
        }
        // TODO: handle failure
    } catch(e) {

    }
    yield put(appActions.setAppLoading(false));
}

function* clearBerthLock(action) {
    if(action.payload) {
        yield put(appActions.setAppLoading(true));
        const request = {
            berth_id: action.payload.berth_id || '',
            lock_token: action.payload.lock_token || ''
        }
        try {
            yield call(reservationService.releaseBerth, request);
        } catch(e) {

        }
        yield put(appActions.setAppLoading(false));
    }
}

export default function* reservationSaga() {
    yield takeLatest(reservationActions.loadReservationProposals, loadReservationProposals);
    yield takeLatest(reservationActions.reloadReservationProposals, reloadReservationProposals);
    yield takeLatest(reservationActions.startTimer, startTimer);
    yield takeLatest(reservationActions.timerTick, timerTick);
    yield takeLatest(reservationActions.proceedTimer, proceedTimer);
    yield takeLatest(reservationActions.createReservation, createReservation);
    yield takeLatest(reservationActions.sendBookingRequest, sendBookingRequest);
    yield takeLatest(reservationActions.finishReservation, finishReservation);
    yield takeLatest(reservationActions.reserveBerth, reserveBerth);
    yield takeLatest(reservationActions.clearBerthLock, clearBerthLock);
}
