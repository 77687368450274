import {all, call, takeLatest, put, select } from "redux-saga/effects";
import {appActions} from "./appSlice";
// import i18n from "../../../i18n";
import marinaService from '../../../services/marina';
import {selectMarinaId} from "./selectors";
import {ReservationRoutes as Routes} from "../../../container/Reservation/routes";
import {reservationActions} from "../reservation/reservationSlice";
import {authActions} from "../auth/authSlice";

function* loadParameters() {
    const params = new URLSearchParams(window.location.search);
    // const language = params.get('lang');
    // yield call(i18n.changeLanguage,language || 'en');
    const marinaId = params.get('marina_id');
    const redirectUrl = params.get('redirect_url');
    const background = params.get('background');
    if(!marinaId) {
        throw Error('ERROR_MISSING_PARAMS');
    }
    const id = parseInt(marinaId);
    if(isNaN(id)) {
        throw Error('ERROR_BAD_MARINA_ID');
    }
    if(background) {
        if(background === 'true') {
            yield put(appActions.setEnableBackground(true));
        } else if(background === 'false') {
            yield put(appActions.setEnableBackground(false));
        }
    }
    yield put(appActions.setMarinaId(id));
    yield put(appActions.setRedirectUrl(redirectUrl || window.location.origin));
    yield put(appActions.setRedirectUrlProvided(!!redirectUrl));
}

function* fetchMarinaData() {
    const marinaId = yield select(selectMarinaId);
    try {
        const res = yield call(marinaService.getMarinas);
        const marina = yield call(marinaService.getMarina, marinaId );
        if(!marina) {
            throw Error('UNKNOWN_MARINA')
        }
        yield put(appActions.setMarina(marina));
        yield put(appActions.setMarinas(res?.map((item) => item.name)));
        try {
            const data = yield call(marinaService.getMarinasData);
            const marinaData = data[marina.code];
            yield put(appActions.setMarinaData(marinaData));
        } catch (e) {
            throw Error('ERROR_LOADING_MARINA')
        }
    } catch (e) {
        throw Error('ERROR_LOADING_MARINA')
    }
}

function* initializeApp() {
    yield put(appActions.setAppLoading(true));
    if(window.location.pathname.endsWith(`${Routes.Finished}`) || window.location.hash.endsWith(`${Routes.Finished}`)) {
        // load data from storage
        const marinaId = sessionStorage.getItem('marinaId');
        if(marinaId) yield put(appActions.setMarinaId(parseInt(marinaId)));
        const redirectUrl = sessionStorage.getItem('redirectUrl');
        if(redirectUrl) yield put(appActions.setRedirectUrl(redirectUrl));
        const redirectUrlProvided = sessionStorage.getItem('redirectUrlProvided');
        if(redirectUrlProvided !== null) yield put(appActions.setRedirectUrlProvided(redirectUrlProvided));
        const background = sessionStorage.getItem('background');
        if(background) yield put(appActions.setEnableBackground(background));
        const marina = sessionStorage.getItem('marina');
        if(marina) yield put(appActions.setMarina(JSON.parse(marina)));
        const marinas = sessionStorage.getItem('marinas');
        if(marinas) yield put(appActions.setMarinas(JSON.parse(marinas)));
        const marinaData = sessionStorage.getItem('marinaData');
        if(marinaData) yield put(appActions.setMarinaData(JSON.parse(marinaData)));
        const user = sessionStorage.getItem('user');
        if(user) yield put(authActions.setUser(JSON.parse(user)));
        const boater = sessionStorage.getItem('boater');
        if(boater) yield put(authActions.setBoater(JSON.parse(boater)));
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        if(isLoggedIn) yield put(authActions.setIsLoggedIn(JSON.parse(isLoggedIn)));
        const usePopeyeBalance = sessionStorage.getItem('reservation.usePopeyeBalance');
        if(usePopeyeBalance) yield put(reservationActions.setUsePopeyeBalance(JSON.parse(usePopeyeBalance || false)));
        const startDate = sessionStorage.getItem('reservation.startDate');
        if(startDate) yield put(reservationActions.setStartDate(JSON.parse(startDate)));
        const endDate = sessionStorage.getItem('reservation.endDate');
        if(endDate) yield put(reservationActions.setEndDate(JSON.parse(endDate)));
        const vesselLength = sessionStorage.getItem('reservation.vesselLength');
        if(vesselLength) yield put(reservationActions.setVesselLength(JSON.parse(vesselLength)));
        const vesselBeam = sessionStorage.getItem('reservation.vesselBeam');
        if(vesselBeam) yield put(reservationActions.setVesselBeam(JSON.parse(vesselBeam)));
        const vesselDraft = sessionStorage.getItem('reservation.vesselDraft');
        if(vesselDraft) yield put(reservationActions.setVesselDraft(JSON.parse(vesselDraft)));
        const firstName = sessionStorage.getItem('reservation.firstName');
        if(firstName) yield put(reservationActions.setFirstName(firstName));
        const lastName = sessionStorage.getItem('reservation.lastName');
        if(lastName) yield put(reservationActions.setLastName(lastName));
        const forceLogin = sessionStorage.getItem('reservation.forceLogin');
        if(forceLogin) yield put(reservationActions.setForceLogin(JSON.parse(forceLogin)));
        const phone = sessionStorage.getItem('reservation.phone');
        if(phone) yield put(reservationActions.setPhone(phone));
        const vesselName = sessionStorage.getItem('reservation.vesselName');
        if(vesselName) yield put(reservationActions.setVesselName(vesselName));
        const notes = sessionStorage.getItem('reservation.notes');
        if(notes) yield put(reservationActions.setNotes(notes));
        const mail = sessionStorage.getItem('reservation.mail');
        if(mail) yield put(reservationActions.setMail(mail));
        const address = sessionStorage.getItem('reservation.address');
        if(address) yield put(reservationActions.setAddress(address));
        const addressLineTwo = sessionStorage.getItem('reservation.addressLineTwo');
        if(addressLineTwo) yield put(reservationActions.setAddressLineTwo(addressLineTwo));
        const postCode = sessionStorage.getItem('reservation.postCode');
        if(postCode) yield put(reservationActions.setPostCode(postCode));
        const country = sessionStorage.getItem('reservation.country');
        if(country) yield put(reservationActions.setCountry(country));
        const proposal = sessionStorage.getItem('reservation.proposal');
        if(proposal) yield put(reservationActions.setReservationProposal(JSON.parse(proposal)));
        const vesselType = sessionStorage.getItem('reservation.vesselType');
        if(vesselType) yield put(reservationActions.setVesselType(vesselType));
        const hullType = sessionStorage.getItem('reservation.hullType');
        if(hullType) yield put(reservationActions.setHullType(hullType));
        const needWaterElectricity = sessionStorage.getItem('reservation.needWaterElectricity');
        if(needWaterElectricity) yield put(reservationActions.setNeedWaterElectricity(JSON.parse(needWaterElectricity)));
        const passeportEscales = sessionStorage.getItem('reservation.passeportEscales');
        if(passeportEscales) yield put(reservationActions.setPasseportEscalse(JSON.parse(passeportEscales)));
        const natureOfTravel = sessionStorage.getItem('reservation.natureOfTravel');
        if(natureOfTravel) yield put(reservationActions.setNatureOfTravel(natureOfTravel));
        const homeMarina = sessionStorage.getItem('reservation.homeMarina');
        if(homeMarina) yield put(reservationActions.setHomeMarina(homeMarina));
        const reservationDetails = sessionStorage.getItem('reservation.reservationDetails');
        if(reservationDetails) yield put(reservationActions.setReservationDetails(JSON.parse(reservationDetails)));
        const startTime = sessionStorage.getItem('reservation.startTime');
        if(startTime) yield put(reservationActions.setTimerSecondsLeft(JSON.parse(startTime)));
        const secondsLeft = sessionStorage.getItem('reservation.secondsLeft');
        if(secondsLeft) yield put(reservationActions.setTimerSecondsLeft(JSON.parse(secondsLeft)));
        const timerExtended = sessionStorage.getItem('reservation.timerExtended');
        if(timerExtended) yield put(reservationActions.setTimerExtended(JSON.parse(timerExtended)));
        const finished = sessionStorage.getItem('reservation.finished');
        if(finished) yield put(reservationActions.setFinished(JSON.parse(finished)));
        yield put(appActions.setIframe(true));

    } else {
        try {
            yield call(loadParameters);
            yield all([
                call(fetchMarinaData)
            ]);
            yield put(appActions.initializeSuccess());
            yield put(appActions.setIframe(true));
        } catch (error) {
            yield put(appActions.initializeFailed({message: error.message}));
        }
    }
    yield put(appActions.setAppLoading(false));
    yield put(appActions.setAppReady(true));
}

function* initializeAppForMarina(action) {
    yield put(appActions.setAppLoading(true));
    if(window.location.pathname.endsWith(`${Routes.Finished}`) || window.location.hash.endsWith(`${Routes.Finished}`)) {
        // load data from storage
        const marinaId = sessionStorage.getItem('marinaId');
        if(marinaId) yield put(appActions.setMarinaId(parseInt(marinaId)));
        const redirectUrl = sessionStorage.getItem('redirectUrl');
        if(redirectUrl) yield put(appActions.setRedirectUrl(redirectUrl));
        const redirectUrlProvided = sessionStorage.getItem('redirectUrlProvided');
        if(redirectUrlProvided !== null) yield put(appActions.setRedirectUrl(redirectUrlProvided));
        const background = sessionStorage.getItem('background');
        if(background) yield put(appActions.setEnableBackground(background));
        const usePopeyeBalance = sessionStorage.getItem('reservation.usePopeyeBalance');
        if(usePopeyeBalance) yield put(reservationActions.setUsePopeyeBalance(JSON.parse(usePopeyeBalance || false)));
        const marina = sessionStorage.getItem('marina');
        if(marina) yield put(appActions.setMarina(JSON.parse(marina)));
        const marinas = sessionStorage.getItem('marinas');
        if(marinas) yield put(appActions.setMarinas(JSON.parse(marinas)));
        const marinaData = sessionStorage.getItem('marinaData');
        if(marinaData) yield put(appActions.setMarinaData(JSON.parse(marinaData)));
        const user = sessionStorage.getItem('user');
        if(user) yield put(authActions.setUser(JSON.parse(user)));
        const boater = sessionStorage.getItem('boater');
        if(boater) yield put(authActions.setBoater(JSON.parse(boater)));
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        if(isLoggedIn) yield put(authActions.setIsLoggedIn(JSON.parse(isLoggedIn)));
        const startDate = sessionStorage.getItem('reservation.startDate');
        if(startDate) yield put(reservationActions.setStartDate(JSON.parse(startDate)));
        const endDate = sessionStorage.getItem('reservation.endDate');
        if(endDate) yield put(reservationActions.setEndDate(JSON.parse(endDate)));
        const vesselLength = sessionStorage.getItem('reservation.vesselLength');
        if(vesselLength) yield put(reservationActions.setVesselLength(JSON.parse(vesselLength)));
        const vesselBeam = sessionStorage.getItem('reservation.vesselBeam');
        if(vesselBeam) yield put(reservationActions.setVesselBeam(JSON.parse(vesselBeam)));
        const vesselDraft = sessionStorage.getItem('reservation.vesselDraft');
        if(vesselDraft) yield put(reservationActions.setVesselDraft(JSON.parse(vesselDraft)));
        const firstName = sessionStorage.getItem('reservation.firstName');
        if(firstName) yield put(reservationActions.setFirstName(firstName));
        const lastName = sessionStorage.getItem('reservation.lastName');
        if(lastName) yield put(reservationActions.setLastName(lastName));
        const forceLogin = sessionStorage.getItem('reservation.forceLogin');
        if(forceLogin) yield put(reservationActions.setForceLogin(JSON.parse(forceLogin)));
        const phone = sessionStorage.getItem('reservation.phone');
        if(phone) yield put(reservationActions.setPhone(phone));
        const vesselName = sessionStorage.getItem('reservation.vesselName');
        if(vesselName) yield put(reservationActions.setVesselName(vesselName));
        const notes = sessionStorage.getItem('reservation.notes');
        if(notes) yield put(reservationActions.setNotes(notes));
        const mail = sessionStorage.getItem('reservation.mail');
        if(mail) yield put(reservationActions.setMail(mail));
        const address = sessionStorage.getItem('reservation.address');
        if(address) yield put(reservationActions.setAddress(address));
        const addressLineTwo = sessionStorage.getItem('reservation.addressLineTwo');
        if(addressLineTwo) yield put(reservationActions.setAddressLineTwo(addressLineTwo));
        const postCode = sessionStorage.getItem('reservation.postCode');
        if(postCode) yield put(reservationActions.setPostCode(postCode));
        const country = sessionStorage.getItem('reservation.country');
        if(country) yield put(reservationActions.setCountry(country));
        const proposal = sessionStorage.getItem('reservation.proposal');
        if(proposal) yield put(reservationActions.setReservationProposal(JSON.parse(proposal)));
        const vesselType = sessionStorage.getItem('reservation.vesselType');
        if(vesselType) yield put(reservationActions.setVesselType(vesselType));
        const hullType = sessionStorage.getItem('reservation.hullType');
        if(hullType) yield put(reservationActions.setHullType(hullType));
        const needWaterElectricity = sessionStorage.getItem('reservation.needWaterElectricity');
        if(needWaterElectricity) yield put(reservationActions.setNeedWaterElectricity(JSON.parse(needWaterElectricity)));
        const passeportEscales = sessionStorage.getItem('reservation.passeportEscales');
        if(passeportEscales) yield put(reservationActions.setPasseportEscales(JSON.parse(passeportEscales)));
        const natureOfTravel = sessionStorage.getItem('reservation.natureOfTravel');
        if(natureOfTravel) yield put(reservationActions.setNatureOfTravel(natureOfTravel));
        const homeMarina = sessionStorage.getItem('reservation.homeMarina');
        if(homeMarina) yield put(reservationActions.setHomeMarina(homeMarina));
        const reservationDetails = sessionStorage.getItem('reservation.reservationDetails');
        if(reservationDetails) yield put(reservationActions.setReservationDetails(JSON.parse(reservationDetails)));
        const secondsLeft = sessionStorage.getItem('reservation.secondsLeft');
        if(secondsLeft) yield put(reservationActions.setTimerSecondsLeft(JSON.parse(secondsLeft)));
        const timerExtended = sessionStorage.getItem('reservation.timerExtended');
        if(timerExtended) yield put(reservationActions.setTimerExtended(JSON.parse(timerExtended)));
        const finished = sessionStorage.getItem('reservation.finished');
        if(finished) yield put(reservationActions.setFinished(JSON.parse(finished)));
        yield put(appActions.setIframe(false));

    } else {
        try {
            yield put(appActions.setMarinaId(action.payload.marina.id));
            yield put(appActions.setRedirectUrlProvided(false));

            const marinaData = action.payload.marinas?.find((m) => m.id === action.payload.marina.id);
            const marina = yield call(marinaService.getMarina, action.payload.marina.id );
            if(!marina || !marinaData) {
                throw Error('UNKNOWN_MARINA')
            }
            yield put(appActions.setIframe(false));
            yield put(appActions.setMarina(marina));
            yield put(appActions.setMarinas(action.payload.marinas?.map((item) => item.name)));
            yield put(appActions.setMarinaData(marinaData));
            yield put(appActions.initializeSuccess());
        } catch (error) {
            yield put(appActions.initializeFailed({message: error.message}));
        }
    }
    yield put(appActions.setAppLoading(false));
    yield put(appActions.setAppReady(true));
}

export default function* appSaga() {
    yield takeLatest(appActions.initialize.type, initializeApp);
    yield takeLatest(appActions.initializeForMarina.type, initializeAppForMarina);
}
